<template>
  <v-container fluid>
    <Radacct :standalone="false" :pppoeUser="$route.params.username"></Radacct>
  </v-container>
</template>

<script>
import Radacct from "../../../components/radius/Radacct";

export default {
  name: "radius.radacct.view",
  components: {
    Radacct
  },
  created: function() {
    this.$store.commit(
      "app/setTitle",
      `Radacct: ${this.$route.params.username}`
    );
  }
};
</script>
